<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="start-date">From</label>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            reset-button
            class="mb-2"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="end-date">To</label>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            reset-button
            class="mb-2"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="align-self-center"
        >
          <div class="d-flex align-content-center">
            <b-button
              variant="primary"
              @click="getRequestedPeriodInvoiceList()"
            >
              <b-spinner
                v-if="isTableLoading"
                small
              />
              Set Period
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <vue-autosuggest
            v-model="company.company_name"
            :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search company..'}"
            :suggestions="[{ data: companyList }]"
            :limit="10"
            @focus="debounceSearch(meta.offset=0, getCompanyList)"
            @input="debounceSearch(meta.offset=0, getCompanyList)"
            @selected="getSelectedCompany"
          >
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{ suggestion.item.company_name }}</span>
            </template>
          </vue-autosuggest>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-end">
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
                @input="debounceSearch(meta.offset=0, getRequestedQuery)"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="invoiceList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              class="action-menu"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.invoice-modal
                @click="getRequestedAction({ id: data.item.id }, 'Download Invoice', 'download',
                                           `/b2e_dashboard/invoice_download/${data.item.invoice_id}/`,
                                           'get', true)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download Invoice</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            class="cursor-pointer"
            :variant="formatClassName(data.item.status)"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="invoiceList"
        @paginate="paginate"
      />
    </b-card>
    <InvoiceActions
      :modal-data="invoiceActionsData"
      @getInvoiceList="getInvoiceList"
    />
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import TablePagination from '@/components/common/TablePagination.vue'
import InvoiceActions from './InvoiceActions.vue'

export default {
  name: 'InvoiceList',
  components: {
    TablePagination,
    VueAutosuggest,
    InvoiceActions,
  },
  mixins: [helper],
  data() {
    return {
      startDate: '',
      endDate: '',
      isTableLoading: false,
      searchQuery: '',
      invoiceList: [],
      companyList: [],
      invoiceActionsData: {},
      company: {
        company_id: '',
        company_name: '',
      },
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        {
          key: 'invoice_id', label: 'INVOICE ID', sortable: true, thClass: 'md',
        },
        {
          key: 'created_at',
          label: 'BOOKING TIME',
          sortable: true,
          formatter: value => this.formatDate(value),
          thClass: 'md',
        },
        {
          key: 'status',
          label: 'STATUS',
          thClass: 'sm',
        },
        {
          key: 'booking_id',
          label: 'BOOKING ID',
          thClass: 'sm',
        },
        {
          key: 'type',
          label: 'TYPE',
          thClass: 'sm',
        },
        {
          key: 'booking_id',
          label: 'BOOKING ID',
          thClass: 'md',
        },
        {
          key: 'net_base_amount',
          label: 'BASE FARE',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'net_tax_amount',
          label: 'TAX',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'total_amount',
          label: 'TOTAL FARE',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'commission_percentage',
          label: 'COMMISSION PERCENTAGE',
          formatter: value => (`${value} %` || 'N/A'),
          thClass: 'md',
        },
        {
          key: 'commission_amount',
          label: 'COMMISSION',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'payable_amount',
          label: 'PAYABLE AMOUNT',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'wallet_amount',
          label: 'STATEMENT BALANCE',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'discount_amount',
          label: 'DISCOUNT',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'refund_amount',
          label: 'REFUND',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'due_amount',
          label: 'DUE AMOUNT',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
        {
          key: 'service_charge',
          label: 'SERVICE CHARGE',
          formatter: value => `BDT ${Number(value).toLocaleString()}`,
          thClass: 'md',
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&start_date=${this.startDate}` : ''
      const endDate = this.endDate ? `&end_date=${this.endDate}` : ''
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const companyId = this.company.company_id ? `&company_id=${this.company.company_id}` : ''
      return `${startDate}${endDate}${search}${companyId}`
    },
  },
  mounted() {
    this.getInvoiceList()
    this.getCompanyList()
  },
  methods: {
    getCompanyList() {
      api.getData('b2e_dashboard/company_list/', true).then(response => {
        if (response.data.status) {
          this.companyList = response.data.result.results
        }
      }).catch()
    },
    getInvoiceList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/invoice/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.invoiceList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    getRequestedQuery() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getInvoiceList()
    },
    getRequestedPeriodInvoiceList() {
      this.isTableLoading = true
      this.meta.offset = 0
      this.getInvoiceList()
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getInvoiceList()
    },
    getSelectedCompany(item) {
      this.company.company_name = item.item.company_name
      this.company.company_id = item.item.company_id
      this.getInvoiceList()
    },
    getRequestedAction(data, title, text, apiEndpoint, apiMethod, isDownload) {
      this.invoiceActionsData = {
        data,
        title,
        text,
        apiEndpoint,
        apiMethod,
        isDownload,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
